import _ from "lodash";
import { OlliePipeEvent, OlliePipeMeta } from "@ollie-sports/models";
import pgFormat from "pg-format";
import { getServerHelpers } from "../helpers";

export async function writePipeEventToDB(events: { event: OlliePipeEvent; meta: OlliePipeMeta }[], attemptNum = 1) {
  // SERVER_ONLY_TOGGLE

  if (process.env.NODE_ENV === "development") {
    return;
  }

  try {
    const { getAnalyticsPgPool } = getServerHelpers();

    if (!events.every(a => a.event.payload && a.event.type)) {
      console.error("Invalid formatted payload! Payload follows");
      console.error(events);
      throw new Error("Invalid formatted payload!");
    }

    try {
      const now = new Date();
      const sql = _(events)
        .groupBy(a => {
          let table = a.event.type.split("-")[0];
          if (!["analytic", "error", "metric"].includes(table)) {
            table = "unknown";
          }
          return table;
        })
        .mapValues(a =>
          a.map(b => {
            let payload = b.event.payload;
            if (payload instanceof Array) {
              payload = Object.assign({}, payload);
            } else if (typeof b.event.payload !== "object") {
              payload = { info: b.event.payload };
            }

            return [
              b.event.type,
              b.meta.account_id || "UNKNOWN",
              JSON.stringify(payload || {}),
              JSON.stringify(b.meta || {}),
              now
            ];
          })
        )
        .mapValues((valuesForTable, table) =>
          pgFormat(`INSERT INTO general_%I (event_type, account_id, payload, meta, created_at) VALUES %L;`, table, valuesForTable)
        )
        .values()
        .join("\n");

      await getAnalyticsPgPool().query(sql);
    } catch (e) {
      if (attemptNum < 6) {
        await new Promise(res => setTimeout(res, 1000));
        return writePipeEventToDB(events, attemptNum + 1);
      } else {
        console.error("Problem writing to Ollie Pipe! Error follows");
        console.error(e);
      }
    }
  } catch (e) {
    console.error("Unexpected problem writing to pipe!");
    // console.error(e);
  }
  // SERVER_ONLY_TOGGLE
}
