import axiosRaw, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { getServerHelpers, getUniversalHelpers } from "../helpers";
import { decryptPIFI } from "./pifi-crypto-helpers";
import type * as ioredis from "ioredis";
import { XMLParser, XMLBuilder, XMLValidator } from "fast-xml-parser";
import _ from "lodash";
import {
  ACHFailureCodes,
  ACHFailureCodesType,
  NMIPaymentResponseInfo,
  CCFailureCodes,
  NMIResponseCodesType,
  isKeyOfNMIResponseCodes,
  CardOrACHCodes,
  GATEWAY_ERROR_CODE_REMAPPING
} from "@ollie-sports/models";
import moment from "moment";
import { logUnexpectedPaymentError } from "./payment-helpers";

const parser = new XMLParser();

//Should only need a lock to perform the work of roughly 2 minutes max for all NMI endpoints
const LOCK_TIME = 120_000;
const nmiAxios = axiosRaw.create({
  headers: {
    "Content-Type": "application/x-www-form-urlencoded"
  },
  transformRequest: [
    data => {
      return Object.keys(data || {})
        .filter(key => data[key] !== undefined)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
    }
  ]
});

/** Transparently Handle Rate Limiting & Throttling with Axios Interceptors **/
const MAX_REQUESTS_COUNT = 10;
const INTERVAL_MS = 500;
let PENDING_REQUESTS = 0;
let retryAtMS = 0; // If we've hit a rate limit, this tells us when to try again.

// Request rate limiting
nmiAxios.interceptors.request.use(function (config) {
  return new Promise(resolve => {
    const tryRequest = () => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT && retryAtMS < Date.now()) {
        PENDING_REQUESTS++;
        resolve({ ...config, timeout: LOCK_TIME - 5000 });
      } else {
        setTimeout(tryRequest, INTERVAL_MS);
      }
    };
    tryRequest();
  });
});

async function handle429Response(response: AxiosResponse) {
  const now = Date.now();

  let backoffTime: number;
  if (retryAtMS && retryAtMS > now) {
    retryAtMS += Math.random() * 50; // Nudge the retry time up a bit.
    backoffTime = retryAtMS - now;
  } else {
    const retryAfter = response.headers["retry-after"];
    backoffTime = 1000;

    if (retryAfter) {
      backoffTime = parseInt(retryAfter, 10) * 1000;
    }

    backoffTime += Math.random() * 2500;
    retryAtMS = now + backoffTime;
  }

  await new Promise(res => setTimeout(res, backoffTime + 10));

  const requestParams = forceToObject(response?.config?.data || {});
  const accountId = requestParams?.merchant_defined_field_1;

  logUnexpectedPaymentError({
    errorCode: "nmi-rate-limit-hit",
    accountId,
    info: {
      response: response.data,
      status: response.status,
      type: requestParams.type,
      url: response.config.url
    },
    locationId: "1221k3jjdfnner",
    olliePipe: getUniversalHelpers().olliePipe,
    paymentInfo: null,
    severity: "low"
  });

  // TODO: Before retyring, query NMI to see if the request actually succeeded. But do we have a transaction id? How would we look it up?
  // It's dumb but rate limited response sometimes actually succeed in the background even though the status code is an error.

  return nmiAxios(response.config);
}

nmiAxios.interceptors.response.use(
  async response => {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);

    const resp = parseNMIResponseData(response.data || "");

    if (resp.response === 3 && resp.responseCode === 301) {
      return handle429Response(response);
    }

    const requestParams = forceToObject(response?.config?.data || {});
    const accountId = requestParams?.merchant_defined_field_1;

    if (accountId) {
      getUniversalHelpers()
        .olliePipe.emitEvent(
          {
            type: "metric-nmi-low-level-response",
            payload: redactSecrets({
              response: resp,
              requestParams
            })
          },
          { accountId }
        )
        .catch(e => {
          console.error("Problem logging nmi-low-level-response");
          console.error(e);
        });
    }

    return response;
  },
  async error => {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);

    if (error.response && error.response.status === 429 && error.response.config) {
      return handle429Response(error.response);
    }

    const requestParams = forceToObject(error?.response?.config?.data || {});
    const accountId = requestParams?.merchant_defined_field_1;

    if (error.response && error.message.match(/socket hang up/i)) {
      logUnexpectedPaymentError({
        errorCode: "nmi-socket-hang-up",
        accountId,
        info: error,
        locationId: "343k4j3jjkjkdr",
        olliePipe: getUniversalHelpers().olliePipe,
        paymentInfo: null,
        severity: "low"
      });
      await new Promise(res => setTimeout(res, 1000));
      return nmiAxios(error.response.config);
    }

    await logUnexpectedPaymentError({
      accountId,
      errorCode: "nmi-low-level-error",
      info: redactSecrets({
        message: error?.message || "",
        requestParams,
        response: error?.response?.data
      }),
      locationId: "erkj3ndmm54m",
      olliePipe: getUniversalHelpers().olliePipe,
      paymentInfo: null
    });

    // If the error is not 429, reject the promise
    return Promise.reject(error);
  }
);

const IS_DEV = () => getServerHelpers().serverConfig.projectId.match(/dev/i);

const NMI_TRANSACT_URL = "https://secure.nmi.com/api/transact.php";
const NMI_QUERY_URL = "https://secure.nmi.com/api/query.php";
const TEST_MERCHANT_SECURITY_KEY = "j8DVk6Kd4nj4eZs6axa59db8Xe5WU239";

export const nmiSDK = {
  async chargeCC(p: {
    chargingOrgId: string;
    fullName: string;
    expMM: string;
    expYYYY: string;
    encryptedCardNumber: string;
    amountCents: number;
    idempotencyKey: string;
    postalCode?: string;
    accountId: string;
  }) {
    // SERVER_ONLY_TOGGLE
    return await performIdempotentWork(p.idempotencyKey, async () => {
      const fullNameArr = p.fullName.trim().split(/\s+/);
      const first_name = fullNameArr.length > 1 ? fullNameArr.slice(0, -1).join(" ") : p.fullName;
      const last_name = fullNameArr.length > 1 ? fullNameArr.pop() : "";
      const nmiSecret = await getOrgNMISecret(p.chargingOrgId);
      const paymentResp = await nmiAxios.post(NMI_TRANSACT_URL, {
        security_key: nmiSecret,
        type: "sale",
        test_mode: IS_DEV() ? "enabled" : undefined,
        ccnumber: await decryptPIFI(p.encryptedCardNumber),
        ccexp: p.expMM + p.expYYYY.slice(2),
        amount: centsToStringDollars(p.amountCents),
        first_name,
        last_name,
        zip: p.postalCode,
        merchant_defined_field_1: p.accountId
      });

      if (!paymentResp || !paymentResp.data) {
        throw new Error("Malformed response from NMI! No response data found");
      }

      return parseNMIResponseData(paymentResp.data);
    });
    // SERVER_ONLY_TOGGLE
  },
  async chargeBankAccount(p: {
    chargingOrgId: string;
    fullName: string;
    amountCents: number;
    routingNumber: string;
    encryptedAccountNumber: string;
    accountType: "savings" | "checking";
    idempotencyKey: string;
    accountId: string;
  }) {
    // SERVER_ONLY_TOGGLE
    return await performIdempotentWork(p.idempotencyKey, async () => {
      const nmiSecret = await getOrgNMISecret(p.chargingOrgId);
      const paymentResp = await nmiAxios.post(NMI_TRANSACT_URL, {
        security_key: nmiSecret,
        type: "sale",
        payment: "check",
        test_mode: getServerHelpers().serverConfig.projectId.match(/dev/i) ? "enabled" : undefined,
        amount: centsToStringDollars(p.amountCents),
        checkaba: p.routingNumber,
        checkaccount: await decryptPIFI(p.encryptedAccountNumber),
        checkname: p.fullName,
        account_type: p.accountType,
        merchant_defined_field_1: p.accountId
      });

      if (!paymentResp || !paymentResp.data) {
        throw new Error("Malformed response from NMI! No response data found");
      }

      return parseNMIResponseData(paymentResp.data);
    });
    // SERVER_ONLY_TOGGLE
  },
  async refundTransaction(p: {
    refundingOrgId: string;
    transactionId: string;
    type: "check" | "creditcard";
    amountCents: number;
    idempotencyKey: string;
    accountId: string;
  }) {
    // SERVER_ONLY_TOGGLE
    return await performIdempotentWork(p.idempotencyKey, async () => {
      const isDev = getServerHelpers().serverConfig.projectId.match(/dev/i);
      const nmiSecret = await getOrgNMISecret(p.refundingOrgId);
      const paymentResp = await nmiAxios.post(NMI_TRANSACT_URL, {
        security_key: nmiSecret,
        type: "refund",
        amount: centsToStringDollars(p.amountCents),
        transactionid: p.transactionId,
        test_mode: isDev ? "enabled" : undefined,
        payment: p.type,
        merchant_defined_field_1: p.accountId
      });

      if (!paymentResp || !paymentResp.data) {
        throw new Error("Malformed response from NMI! No response data found");
      }

      return parseNMIResponseData(paymentResp.data);
    });
    // SERVER_ONLY_TOGGLE
  },
  async verifyBankAccount(p: {
    fullName: string;
    routingNumber: string;
    accountNumber: string;
    accountType: "savings" | "checking";
    accountId: string;
  }): Promise<{ type: "success" } | { type: "failure"; fallbackErrorText?: string; errorCode?: CardOrACHCodes }> {
    // SERVER_ONLY_TOGGLE
    try {
      const ollieSecurityKey = getServerHelpers().serverConfig.nmiOllieMerchantAccountSecret;
      const authorizeInfo = await nmiAxios
        .post(NMI_TRANSACT_URL, {
          security_key: ollieSecurityKey,
          type: "sale",
          payment: "check",
          test_mode: IS_DEV() ? "enabled" : undefined,
          amount: IS_DEV() ? "1.00" : "0.01",
          checkaba: p.routingNumber,
          checkaccount: p.accountNumber,
          checkname: p.fullName,
          account_type: p.accountType,
          merchant_defined_field_1: p.accountId
        })
        .then(a => parseNMIResponseData(a.data));

      if (authorizeInfo.response !== 1 || !authorizeInfo.transactionId) {
        return {
          type: "failure",
          errorCode: authorizeInfo.responseCode,
          fallbackErrorText: authorizeInfo.responseText
        };
      }

      const voidVerifyCharge = (count = 0) =>
        nmiSDK
          .voidTransaction({
            orgId: { securityKey: ollieSecurityKey },
            transactionId: authorizeInfo.transactionId!,
            type: "check"
          })
          .then(
            resp => {
              if (count < 4 && resp.responseText.match(/cannot be voided for 30 seconds/)) {
                setTimeout(() => voidVerifyCharge(count + 1), 1000 * 31);
              } else if (resp.status !== "success") {
                logVoidingError(resp);
              }
            },
            err => {
              logVoidingError(err);
            }
          );

      const logVoidingError = async (info: any) => {
        await logUnexpectedPaymentError({
          accountId: p.accountId,
          errorCode: "nmi-problem-voiding-cc-auth",
          info: {
            info: { accountType: p.accountType, fullName: p.fullName, routingNumber: p.routingNumber },
            responseInfo: info
          },
          locationId: "ermdklerjennccj",
          olliePipe: getUniversalHelpers().olliePipe,
          paymentInfo: null
        });
      };

      voidVerifyCharge();

      return { type: "success" };
    } catch (e: any) {
      await logUnexpectedPaymentError({
        accountId: p.accountId,
        errorCode: "nmi-unknown-verify-bank-account-error",
        info: {
          error: printError(e),
          request: {
            accountType: p.accountType,
            routing: p.routingNumber,
            fullName: p.fullName
          }
        },
        locationId: "234kjcdvsdxuiuellk",
        olliePipe: getUniversalHelpers().olliePipe,
        paymentInfo: null
      });

      return { type: "failure" };
    }
    // SERVER_ONLY_TOGGLE
  },
  async verifyCC(p: {
    fullName: string;
    number: string;
    expMM: string;
    expYYYY: string;
    cvv?: string;
    postalCode?: string;
    accountId: string;
  }): Promise<{ type: "success" } | { type: "failure"; fallbackErrorText?: string; errorCode?: CardOrACHCodes }> {
    // SERVER_ONLY_TOGGLE
    const safeInfo = {
      fullName: p.fullName,
      last4: p.number.toString().slice(-4),
      expMM: p.expMM,
      expYYY: p.expYYYY,
      postalCode: p.postalCode
    };

    try {
      const fullNameArr = p.fullName.trim().split(/\s+/);
      const first_name = fullNameArr.length > 1 ? fullNameArr.slice(0, -1).join(" ") : p.fullName;
      const last_name = fullNameArr.length > 1 ? fullNameArr.pop() : "";
      const ollieSecurityKey = getServerHelpers().serverConfig.nmiOllieMerchantAccountSecret;
      const authorizeInfo = await nmiAxios
        .post(NMI_TRANSACT_URL, {
          security_key: ollieSecurityKey,
          type: "auth",
          ccnumber: p.number,
          ccexp: p.expMM + p.expYYYY.slice(2),
          cvv: p.cvv,
          amount: IS_DEV() ? "1.00" : "0.01",
          first_name,
          last_name,
          zip: p.postalCode,
          test_mode: IS_DEV() ? "enabled" : undefined,
          merchant_defined_field_1: p.accountId
        })
        .then(a => parseNMIResponseData(a.data));

      if (authorizeInfo.response !== 1 || !authorizeInfo.transactionId) {
        return { type: "failure", fallbackErrorText: authorizeInfo.responseText, errorCode: authorizeInfo.responseCode };
      }

      const voidInfo = await nmiSDK.voidTransaction({
        orgId: { securityKey: ollieSecurityKey },
        transactionId: authorizeInfo.transactionId,
        type: "creditcard"
      });

      if (voidInfo.response !== 1) {
        await logUnexpectedPaymentError({
          accountId: p.accountId,
          errorCode: "nmi-problem-voiding-cc-auth",
          info: { info: safeInfo, voidResponse: voidInfo },
          locationId: "34kj43jkcvnnmmdemddd",
          olliePipe: getUniversalHelpers().olliePipe,
          paymentInfo: null
        });

        return { type: "failure", errorCode: voidInfo.responseCode };
      }

      return { type: "success" };
    } catch (e) {
      await logUnexpectedPaymentError({
        accountId: p.accountId,
        errorCode: "nmi-unknown-verify-bank-account-error",
        info: {
          error: printError(e),
          request: safeInfo
        },
        locationId: "dskjdkkfdjkdkkkkkkk",
        olliePipe: getUniversalHelpers().olliePipe,
        paymentInfo: null
      });

      return { type: "failure" };
    }
    // SERVER_ONLY_TOGGLE
  },
  async queryTransactions(p: {
    orgId: string | { securityKey: string };
    startDateMS?: number;
    endDateMS?: number;
    transaction_id?: string | string[];
    first_name?: string; //CC first name only
    last_name?: string; //CC last name only
    check_name?: string;
    check_account?: string;
    zip?: string;
    cc_number?: string; //Can be last 4 or entire number
    condition?: (
      | "pending"
      | "pendingsettlement"
      | "in_progress"
      | "condition"
      | "abandoned"
      | "failed"
      | "canceled"
      | "pending"
      | "complete"
      | "unknown"
    )[];
    transaction_type?: ("cc" | "ck" | "cs")[];
    action_type?: ("sale" | "refund" | "credit" | "auth" | "capture" | "void" | "return" | "chargebacks" | "validate")[];
    account_id?: string;
  }): Promise<NMITransaction[]> {
    // SERVER_ONLY_TOGGLE
    const { orgId, endDateMS, startDateMS, ...nmiParams } = p;

    const nmiSecret = typeof p.orgId === "string" ? await getOrgNMISecret(p.orgId) : p.orgId.securityKey;

    const resp = await nmiAxios.post(
      NMI_QUERY_URL,
      {
        security_key: nmiSecret,
        ...(startDateMS ? { start_date: moment(startDateMS).format("YYYYMMDDhhmmss") } : {}),
        ...(endDateMS ? { end_date: moment(endDateMS).format("YYYYMMDDhhmmss") } : {}),
        ..._.mapValues(nmiParams || {}, a => (a instanceof Array ? a.join(",") : a))
      },
      {
        headers: {
          accept: "application/json"
        }
      }
    );

    const traw1 = parser.parse(resp.data).nm_response.transaction;

    if (!traw1) {
      return [];
    }

    const traw2: NMITransaction[] = traw1 instanceof Array ? traw1 : [traw1];

    return traw2.map(a => ({ ...a, action: toArray(a.action) }));

    // SERVER_ONLY_TOGGLE
  },

  async verifyConnectivityForOrgId(orgId: string) {
    // SERVER_ONLY_TOGGLE
    try {
      const resp = await nmiAxios.post("https://secure.networkmerchants.com/api/query.php", {
        security_key: await getOrgNMISecret(orgId),
        result_limit: 1
      });

      const hasErrorTag = typeof resp.data === "string" ? !!resp.data.match(/\berror_response\b/) : false;

      return resp.status >= 200 && resp.status < 300 && !hasErrorTag;
    } catch (e) {
      return false;
    }
    // SERVER_ONLY_TOGGLE
  },
  voidTransaction: async (
    p: { orgId: string | { securityKey: string }; type: "creditcard" | "check"; transactionId: string },
    count = 0
  ): Promise<NMIPaymentResponseInfo> => {
    const nmiSecret = typeof p.orgId === "string" ? await getOrgNMISecret(p.orgId) : p.orgId.securityKey;
    return nmiAxios
      .post(NMI_TRANSACT_URL, {
        security_key: nmiSecret,
        type: "void",
        transactionid: p.transactionId,
        payment: p.type,
        test_mode: IS_DEV() ? "enabled" : undefined
      })
      .then(async a => {
        const info = parseNMIResponseData(a.data);

        if (count < 5 && info.response !== 1) {
          await new Promise(res => setTimeout(res, 1000));
          return await nmiSDK.voidTransaction(p, count + 1);
        } else {
          return info;
        }
      })
      .catch(async e => {
        if (count > 5) {
          throw e;
        } else {
          await new Promise(res => setTimeout(res, 1000));
          return nmiSDK.voidTransaction(p, count + 1);
        }
      });
  }
};

async function getOrgNMISecret(orgId: string) {
  // SERVER_ONLY_TOGGLE
  if (getServerHelpers().serverConfig.projectId.match(/dev/i)) {
    //Always return test merchant in dev firestore environment.
    return TEST_MERCHANT_SECURITY_KEY;
  }

  const { appOllieFirestoreV2: h } = getServerHelpers();
  const orgSecret = await h.OrgSecret.getDoc(orgId);
  if (!orgSecret) {
    throw new Error("Org id does not exist! " + orgId);
  }

  if (!orgSecret.nmiConfig) {
    throw new Error("Org does not have NMI credentials set! " + orgId);
  }

  return decryptPIFI(orgSecret.nmiConfig.encryptedMerchantApiKey);
  // SERVER_ONLY_TOGGLE
}

function parseNMIResponseData(data: string): NMIPaymentResponseInfo {
  // SERVER_ONLY_TOGGLE
  const parsedData = data.split("&").reduce((acc, pair) => {
    let [key, value] = pair.split("=");
    acc[key] = decodeURIComponent(value || "");
    return acc;
  }, {} as Record<string, any>);

  let responseCode = parseInt(parsedData["response_code"]) as CardOrACHCodes;
  const responseText = parsedData["responsetext"];
  const response = parseInt(parsedData["response"]);
  const transactionId = parsedData["transactionid"];

  if (response === 1) {
    return {
      status: "success",
      response,
      responseCode,
      transactionId,
      responseText
    };
  } else if (responseCode === 300) {
    const [__, remappedResponseCode] = GATEWAY_ERROR_CODE_REMAPPING.find(a => !!responseText.match(a[0])) || [];

    if (typeof remappedResponseCode === "string" || typeof remappedResponseCode === "number") {
      return {
        status: "failure",
        response,
        responseCode: remappedResponseCode,
        transactionId,
        responseText
      };
    } else {
      return {
        status: "catastrophicError",
        response,
        responseCode,
        responseText,
        transactionId
      };
    }
  } else {
    return {
      status: "failure",
      response,
      responseCode,
      transactionId: parsedData["transactionid"],
      responseText: parsedData["responsetext"]
    };
  }
  // SERVER_ONLY_TOGGLE
}

let client: ioredis.Redis = null as any;
async function performIdempotentWork(
  key: string,
  doWork: () => Promise<NMIPaymentResponseInfo>
): Promise<NMIPaymentResponseInfo> {
  // SERVER_ONLY_TOGGLE
  if (!client) {
    const { ioredis } = getServerHelpers().injectedServerLibraries;
    client = new ioredis.Redis();
  }

  const prevResp = await client.get(`nmi_response:${key}`);

  if (prevResp) {
    return JSON.parse(prevResp);
  }

  const start = Date.now();

  try {
    const lock = await client.set(`nmi_lock:${key}`, "locked", "PX", LOCK_TIME, "NX");

    //Handle if there's no response but it can't obtain a lock. E.g. Two requests arrived almost simultaneously.
    if (!lock) {
      //Note that this while loop will always terminate the function since it will end with with either a recursive retry or with a saved response from an other thread.
      while (true) {
        //Polling is fine in this case. Will usually just be a couple polls (max of 30) and it's way simpler than a Redis PubSub subscription
        await new Promise(res => setTimeout(res, 1000));
        const [pollResp, currLock] = await Promise.all([client.get(`nmi_response:${key}`), client.get(`nmi_lock:${key}`)]);

        if (pollResp) {
          return JSON.parse(pollResp);
        }

        if (!currLock) {
          //The thread that had the lock errored out or expired, so try to gain our own lock and retry
          return performIdempotentWork(key, doWork);
        }
      }
    }

    const resp = await doWork();

    if (Date.now() - start >= LOCK_TIME) {
      //Should never happen since since doWork would have errored out by now due to a request timeout since its been LOCK_TIME. But just in case...
      await logUnexpectedPaymentError({
        accountId: key,
        errorCode: "nmi-lock-time-exceeded",
        info: {
          idempotencyKey: key
        },
        locationId: "ekjrjdfnddddfdcnxcnn",
        olliePipe: getUniversalHelpers().olliePipe,
        paymentInfo: null
      });

      return resp;
    }

    //Should only save the response if successful AND only for 24 hours
    if (resp.status === "success") {
      await client.set(`nmi_response:${key}`, JSON.stringify(resp), "EX", 60 * 60 * 24);
    }
    await client.del(`nmi_lock:${key}`);
    return resp;
  } catch (e) {
    await client.del(`nmi_lock:${key}`);
    throw e;
  }
  // SERVER_ONLY_TOGGLE
}

function centsToStringDollars(cents: number) {
  return (cents / 100).toFixed(2);
}

function printError(e: any) {
  return e && e instanceof Object
    ? ["message", "stack", ...Object.keys(e)]
        .map(a => (e[a] instanceof Object ? JSON.stringify(e[a]) : e[a]))
        .filter(a => a)
        .join("\n")
    : JSON.stringify(e, null, 2);
}

export interface NMITransaction {
  transaction_id: number;
  partial_payment_id: string;
  partial_payment_balance: string;
  platform_id: string;
  transaction_type: "ck" | "cc";
  condition: "canceled" | "complete" | "failed" | "pendingsettlement";
  order_id: string;
  authorization_code: number | string;
  ponumber: string;
  order_description: string;
  first_name: number | string;
  last_name: string;
  address_1: string;
  address_2: string;
  company: string;
  city: string;
  state: string;
  postal_code: number | string;
  country: string;
  email: string;
  phone: string;
  fax: string;
  cell_phone: string;
  customertaxid: string;
  customerid: string;
  website: string;
  shipping_first_name: string;
  shipping_last_name: string;
  shipping_address_1: string;
  shipping_address_2: string;
  shipping_company: string;
  shipping_city: string;
  shipping_state: string;
  shipping_postal_code: string;
  shipping_country: string;
  shipping_email: string;
  shipping_carrier: string;
  tracking_number: string;
  shipping_date: string;
  shipping: number;
  shipping_phone: string;
  cc_number: string;
  cc_hash: string;
  cc_exp: number | string;
  cavv: string;
  cavv_result: string;
  xid: string;
  eci: string;
  directory_server_id: string;
  three_ds_version: string;
  merchant_defined_field: string; //This is the user account id
  avs_response: "" | "A" | "N" | "R" | "S" | "U" | "W" | "Y" | "Z" | number;
  csc_response: "M" | "N" | "P";
  cardholder_auth: string;
  cc_start_date: string;
  cc_issue_number: string;
  check_account: string;
  check_hash: string;
  check_aba: number | string;
  check_name: string;
  account_holder_type: "" | "personal" | "business";
  account_type: "" | "checking" | "savings";
  sec_code: "WEB" | "";
  drivers_license_number: string;
  drivers_license_state: string;
  drivers_license_dob: string;
  social_security_number: string;
  processor_id: string;
  tax: number;
  currency: string;
  surcharge: string;
  cash_discount: string;
  tip: string;
  card_balance: string;
  card_available_balance: string;
  entry_mode: string;
  cc_bin: number | string;
  cc_type: "American Express" | "Discover" | "Mastercard" | "Visa";
  signature_image: string;
  duty_amount: string;
  discount_amount: string;
  national_tax_amount: string;
  summary_commodity_code: string;
  vat_tax_amount: string;
  vat_tax_rate: string;
  alternate_tax_amount: string;
  action: TransactionAction[];
  original_transaction_id?: number;
}

export interface TransactionAction {
  amount: number;
  action_type: "auth" | "check_late_return" | "check_return" | "refund" | "sale" | "settle" | "validate" | "void";
  date: number;
  success: number;
  ip_address: string;
  source: string;
  api_method: string;
  tap_to_mobile: boolean;
  username: "larocafc" | string; //The username of the club in NMI
  response_text: string;
  batch_id: number;
  processor_batch_id: number | string;
  response_code: number;
  processor_response_text: string;
  processor_response_code: "N7" | "" | number;
  requested_amount?: number;
  device_license_number: string;
  device_nickname: string;
}

function toArray(a: any) {
  return a instanceof Array ? a : !!a ? [a] : [];
}
const SECRETS = {
  number: { last4: true },
  cc_number: { last4: true },
  accountNumber: { last4: true },
  account_number: { last4: true },
  ollieSecurityKey: true,
  securityKey: true,
  security_key: true,
  encryptedAccountNumber: true,
  encryptedCardNumber: true,
  encryptedMerchantApiKey: true,
  cvv: true,
  expMM: true,
  expYYYY: true
} as Record<string, true | { last4: true }>;

function redactSecrets(obj: any) {
  // Check if the input is an object or array
  if (typeof obj === "object" && obj !== null) {
    // Loop through each key in the object
    for (const key in obj) {
      // If the key is one of the secrets, redact it
      if (SECRETS[key]) {
        obj[key] = typeof SECRETS[key] === "object" ? "******" + String(obj[key]).slice(-4) : "REDACTED";
      } else {
        // If the value is an object or array, recursively redact its secrets
        redactSecrets(obj[key]);
      }
    }
  }
  return obj;
}

function forceToObject(str: any) {
  if (typeof str === "string") {
    // Split the string by '&' to get key-value pairs
    const pairs = str.split("&");
    const result: Record<string, any> = {};

    pairs.forEach(pair => {
      // Split each pair by '=' to separate keys and values
      const [key, value] = pair.split("=");
      // Decode the key and value, then assign them to the result object
      result[decodeURIComponent(key)] = decodeURIComponent(value);
    });

    return result;
  } else {
    return str || {};
  }
}
