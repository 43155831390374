import _, { values } from "lodash";
import { customNanoid } from "./nanoid-utils";
import {
  AccountId,
  OrgFeeDetails,
  OrgInvoice,
  OrgInvoiceChild,
  OrgInvoiceParent,
  OrgInvoiceTypes,
  OrgPayment,
  OrgPaymentInvoice,
  OrgPaymentInvoiceCredit,
  OrgPaymentInvoiceDefault,
  OrgPaymentRefund,
  OrgPaymentType,
  OverallInvoiceStatus,
  PaymentMethodType,
  PlayerBundleId
} from "@ollie-sports/models";
import { translate } from "@ollie-sports/i18n";
import moment from "moment";
import { calculateFeesForPayment } from "./payment-utils";
import { fetchConstrainedToBalancePaymentDetails, filterOrgPaymentInvoices } from "./org-payment-utils";
import { triggerForPaymentNotifications } from "../api/notification/payment-helpers";
import { getServerHelpers, getUniversalHelpers } from "../helpers";
import {
  PayInvoiceUnexpectedErrorStatusCodes,
  ChargeCardUnexpectedErrorStatusCodes,
  logUnexpectedPaymentError,
  chargeWithNMIForInvoice
} from "./payment-helpers";
import { getDefaultPaymentAccount } from "../compute/account.compute";

export function generateOrgInvoiceId() {
  return customNanoid(12);
}

type IndividualInvoiceStatus = "paid" | "partial" | "failed" | "pastDue" | "scheduled" | "unpaid";

export function getIndividualOrgInvoiceStatus(p: { orgInvoice: OrgInvoice }): IndividualInvoiceStatus {
  if (p.orgInvoice.thisInvoicePaidInFullDateMS) {
    return "paid";
  }
  if (p.orgInvoice.derivedTotalAmountPaidCentsBeforeAllFees && !isChildOrgInvoice(p.orgInvoice)) {
    return "partial";
  }
  if (
    (p.orgInvoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
      p.orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment) &&
    p.orgInvoice.numberOfFailedPaymentAttempts
  ) {
    return "failed";
  }
  if (p.orgInvoice.dueDateMS < moment().valueOf()) {
    return "pastDue";
  }
  if (isChildOrgInvoice(p.orgInvoice)) {
    return "scheduled";
  }
  return "unpaid";
}

export function getOverallOrgInvoiceStatus(p: {
  parentOrgInvoice: OrgInvoiceParent;
  orgInvoiceChildren: OrgInvoiceChild[];
  allOrgPayments: OrgPayment[]; //All payments linked to the parent OR children
}): OverallInvoiceStatus {
  const allOrgInvoices = [p.parentOrgInvoice, ...p.orgInvoiceChildren];
  if (!allOrgInvoices.find(oi => !oi.thisInvoicePaidInFullDateMS)) {
    return OverallInvoiceStatus.paid;
  }
  if (!p.parentOrgInvoice.thisInvoicePaidInFullDateMS) {
    if (p.parentOrgInvoice.dueDateMS < moment().valueOf()) {
      return OverallInvoiceStatus.late;
    } else {
      return OverallInvoiceStatus.outstanding;
    }
  }

  if (
    allOrgInvoices.find(
      oi =>
        !oi.thisInvoicePaidInFullDateMS &&
        !p.allOrgPayments.find(op => op.type === OrgPaymentType.invoiceDefault) &&
        !!p.allOrgPayments.find(op => op.type === OrgPaymentType.invoiceFailedPayment)
    )
  ) {
    return OverallInvoiceStatus.invoiceFailedPayment;
  }
  if (allOrgInvoices.find(oi => !oi.thisInvoicePaidInFullDateMS && oi.dueDateMS < moment().valueOf())) {
    return OverallInvoiceStatus.latePaymentInstallment;
  }

  return OverallInvoiceStatus.inProgress;
}

export function isParentOrgInvoice(orgInvoice: OrgInvoice): orgInvoice is OrgInvoiceParent {
  return orgInvoice.type === OrgInvoiceTypes.manual || orgInvoice.type === OrgInvoiceTypes.registration;
}
export function isChildOrgInvoice(orgInvoice: OrgInvoice) {
  return (
    orgInvoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
    orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment
  );
}

export type IndividualOrgInvoiceDetails = {
  totalAmount: number;
  paidAmount: number;
  creditAmount: number;
  remainingAmount: number;
  status: IndividualInvoiceStatus;
  lateFeesPaidAmount: number;
  otherFeesPaidAmount: number;
  subtotalRemainingAmountDueCents: number;
  lateFeesAmountDueCents: number;
  estimatedOtherFeesAmountDueCentsOnRemainingAmount: number;
  totalRemainingAmountDueCents: number;
};

export function getRefundAvailabilityDetails(p: { payment: OrgPaymentInvoiceDefault; refunds: OrgPaymentRefund[] }) {
  const totalAmountPaidCents =
    (p.payment.amountCents || 0) + (p.payment.lateFeeAmountCents || 0) + (p.payment.processingFeeAmountCents || 0);
  const amountRefundedPreviously = p.refunds.reduce((a, b) => a + b.totalAmountRefundedCents, 0);
  const maxPossibleRefundCents = totalAmountPaidCents - amountRefundedPreviously;
  const refundWindowIsOpen = moment(p.payment.createdAtMS).isAfter(moment().subtract(1, "year"));

  return {
    totalAmountPaidCents,
    amountRefundedPreviously,
    maxPossibleRefundCents,
    refundWindowIsOpen,
    canIssueRefund: maxPossibleRefundCents > 0 && refundWindowIsOpen
  };
}

export function getIndividualOrgInvoiceAmountDetails(p: {
  orgInvoice: OrgInvoice;
  orgPayments: OrgPayment[];
  feeDetails?: OrgFeeDetails;
  paymentMethodType: PaymentMethodType;
}): IndividualOrgInvoiceDetails {
  let lateFeesPaidAmount = _.sum(
    p.orgPayments.filter(op => op.type === OrgPaymentType.invoiceDefault).map(op => op.lateFeeAmountCents ?? 0)
  );

  let lateFeesAmountDueCents =
    !p.orgInvoice.thisInvoicePaidInFullDateMS && p.orgInvoice.dueDateMS < moment().valueOf() && !lateFeesPaidAmount
      ? p.orgInvoice.lateFeeCentsToBeIssuedIfLate
      : 0;

  let totalAmount = p.orgInvoice.amountDueCents + lateFeesAmountDueCents;
  let paidAmount = _.sum(p.orgPayments.filter(op => op.type === OrgPaymentType.invoiceDefault).map(op => op.amountCents));
  let creditAmount = _.sum(p.orgPayments.filter(op => op.type === OrgPaymentType.invoiceCredit).map(op => op.amountCents));
  let otherFeesPaidAmount = _.sum(
    p.orgPayments.filter(op => op.type === OrgPaymentType.invoiceDefault).map(op => op.processingFeeAmountCents ?? 0)
  );
  let remainingAmountDueCents = totalAmount - paidAmount - creditAmount;
  const subtotalRemainingAmountDueCents = remainingAmountDueCents + lateFeesAmountDueCents;
  const estimatedOtherFeesAmountDueCentsOnRemainingAmount = calculateFeesForPayment({
    chargeAmountCents: subtotalRemainingAmountDueCents,
    feeDetails: p.feeDetails,
    paymentMethodType: p.paymentMethodType
  });
  const totalRemainingAmountDueCents = subtotalRemainingAmountDueCents + estimatedOtherFeesAmountDueCentsOnRemainingAmount;
  let status = getIndividualOrgInvoiceStatus(p);

  return {
    totalAmount,
    paidAmount,
    creditAmount,
    remainingAmount: remainingAmountDueCents,
    status,
    lateFeesPaidAmount,
    otherFeesPaidAmount,
    subtotalRemainingAmountDueCents,
    lateFeesAmountDueCents,
    estimatedOtherFeesAmountDueCentsOnRemainingAmount,
    totalRemainingAmountDueCents
  };
}
export function getOverallOrgInvoiceAmountDetails(p: {
  parentOrgInvoice: OrgInvoiceParent;
  childrenOrgInvoices: OrgInvoiceChild[];
  orgPayments: OrgPayment[];
}) {
  const pastPaidLateFeesAmount = p.orgPayments
    .filter(a => a.type === OrgPaymentType.invoiceDefault)
    .reduce((a, b) => a + (b.lateFeeAmountCents || 0), 0);

  const orgInvoicePayments = filterOrgPaymentInvoices(p.orgPayments);

  const currentlyDueLateFeesAmount = p.childrenOrgInvoices.reduce((acc, inv) => {
    const thisInvoiceCurrLateFees =
      inv.derivedTotalAmountPaidCentsBeforeAllFees < inv.amountDueCents && inv.dueDateMS < Date.now()
        ? inv.lateFeeCentsToBeIssuedIfLate
        : 0;
    return acc + thisInvoiceCurrLateFees;
  }, 0);

  let totalAmount = p.parentOrgInvoice.derivedTotalAmountDueCentsIncludingChildrenInvoices;
  let paidAmount = [p.parentOrgInvoice, ...p.childrenOrgInvoices].reduce((acc, val, index) => {
    const orgPaymentsForInvoice = orgInvoicePayments.filter(
      op => op.invoiceId === val.id && op.type === OrgPaymentType.invoiceDefault
    );
    acc = acc + _.sum(orgPaymentsForInvoice.map(op => op.amountCents));
    return acc;
  }, 0);
  let creditAmount = [p.parentOrgInvoice, ...p.childrenOrgInvoices].reduce((acc, val) => {
    const orgPaymentsForInvoice = orgInvoicePayments.filter(
      op => op.invoiceId === val.id && op.type === OrgPaymentType.invoiceCredit
    );
    acc = acc + _.sum(orgPaymentsForInvoice.map(op => op.amountCents));
    return acc;
  }, 0);

  let status = getOverallOrgInvoiceStatus({
    allOrgPayments: orgInvoicePayments,
    orgInvoiceChildren: p.childrenOrgInvoices,
    parentOrgInvoice: p.parentOrgInvoice
  });

  let otherFeesPaidAmount = [p.parentOrgInvoice, ...p.childrenOrgInvoices].reduce((acc, val, index) => {
    const orgPaymentsForInvoice = orgInvoicePayments.filter(
      op => op.invoiceId === val.id && op.type === OrgPaymentType.invoiceDefault
    );
    acc = acc + _.sum(orgPaymentsForInvoice.map(op => op.processingFeeAmountCents ?? 0));
    return acc;
  }, 0);

  let remainingAmount = totalAmount + currentlyDueLateFeesAmount - paidAmount - creditAmount;

  return {
    totalAmount,
    paidAmount,
    creditAmount,
    remainingAmount,
    status,
    pastPaidLateFeesAmount,
    currentlyDueLateFeesAmount,
    otherFeesPaidAmount
  };
}
export function isIndividualOrgInvoiceEligibleForCreditDeletion(p: { orgInvoice: OrgInvoice; orgPayments: OrgPaymentInvoice[] }) {
  const hasPayment = !!p.orgPayments.find(op => op.invoiceId === p.orgInvoice.id && op.type === OrgPaymentType.invoiceDefault);
  const credits = p.orgPayments.filter(op => op.type === OrgPaymentType.invoiceCredit && op.invoiceId === p.orgInvoice.id);
  const totalCreditAmount = _.sum(credits.map(c => c.amountCents));
  const dueDateHasPassed = p.orgInvoice.dueDateMS < moment().valueOf();

  if (hasPayment) {
    return false;
  } else if (dueDateHasPassed && p.orgInvoice.thisInvoicePaidInFullDateMS) {
    return false;
  }
  return true;
}

export async function getInvoicesNeedingAttentionForPlayerBundleIds(p: { playerBundleIds: PlayerBundleId[] }) {
  const { appOllieFirestoreV2: h, getAppPgPool } = getServerHelpers();
  return (
    await getAppPgPool().query(
      `select * from f_parent_org_invoice_status(null, $1)
  where status != 'inProgress' and status != 'paid';`,
      [p.playerBundleIds]
    )
  ).rows.map(r => {
    return {
      orgId: r["org_id"],
      orgInvoiceId: r["parent_org_invoice_id"],
      playerBundleId: r["player_bundle_id"],
      status: r["status"]
    };
  });
}

export async function triggerPaymentOnIndividualOrgInvoice(p: {
  orgInvoice: OrgInvoice;
  accountId: string;
  isAutoPayment: boolean;
}) {
  const accountId = p.accountId;

  if (!accountId) {
    throw new Error("Must have account id scheduled to pay invoice! " + p.orgInvoice.id);
  }

  const { appOllieFirestoreV2: h } = getServerHelpers();
  const [payments, accountSecret, accountPrivate, orgSettings] = await Promise.all([
    h.OrgPayment.query({ where: [{ invoiceId: ["==", p.orgInvoice.id] }] }),
    h.AccountSecret.getDoc(accountId),
    h.AccountPrivate.getDoc(accountId),
    h.OrgSettings.getDoc(p.orgInvoice.orgId)
  ]);

  if (!accountSecret || !orgSettings || !accountPrivate) {
    throw new Error("Unable to find accountSecret or orgSettings! " + p.orgInvoice.id);
  }

  const defaultPaymentMethod = getDefaultPaymentAccount(Object.values(accountSecret?.paymentMethodsById ?? {}));

  if (!defaultPaymentMethod) {
    throw new Error("No default payment method found!");
  }

  const paymentDetails = getIndividualOrgInvoiceAmountDetails({
    orgInvoice: p.orgInvoice,
    orgPayments: payments.docs,
    feeDetails: orgSettings?.customFeeDetails?.[defaultPaymentMethod.type],
    paymentMethodType: defaultPaymentMethod.type
  });

  return await payIndividualOrgInvoice({
    accountId,
    isAutoPayment: p.isAutoPayment,
    locale: accountPrivate.communicationLocale,
    orgInvoice: p.orgInvoice,
    todayPaymentDetails: {
      baseAmountDueCents: paymentDetails.remainingAmount,
      otherFeesAmountDueCents: paymentDetails.estimatedOtherFeesAmountDueCentsOnRemainingAmount,
      lateFeeAmountDueCents: paymentDetails.lateFeesAmountDueCents
    }
  });
}

export async function payIndividualOrgInvoice(p: {
  orgInvoice: OrgInvoice;
  todayPaymentDetails: {
    baseAmountDueCents: number;
    lateFeeAmountDueCents?: number;
    otherFeesAmountDueCents: number;
  };
  nonDefaultPaymentMethodIdToUse?: string;
  manualTriggerMS?: number;
  accountId: AccountId;
  isAutoPayment: boolean;
  locale: string;
  isImmediateRetry?: boolean;
}): Promise<
  | { type: "success" }
  | {
      type: "error";
      prettyErrorReason: string;
      errorCode: PayInvoiceUnexpectedErrorStatusCodes | ChargeCardUnexpectedErrorStatusCodes;
    }
  | { type: "failed"; prettyFailureReason: string }
> {
  // SERVER_ONLY_TOGGLE

  const { appOllieFirestoreV2: h } = getServerHelpers();
  const { olliePipe } = getUniversalHelpers();
  const nowMS = Date.now();
  const today = moment().format("YYYY-MM-DD");

  try {
    if (!!p.orgInvoice.thisInvoicePaidInFullDateMS) {
      logUnexpectedPaymentError({
        errorCode: "pay-individual-invoice-already-paid",
        olliePipe,
        accountId: p.accountId,
        paymentInfo: null,
        info: null,
        locationId: "2kjdfkj34kjdfnMDMdlkj"
      });
      return {
        type: "error",
        prettyErrorReason: translate({
          defaultMessage: "This invoice has already been paid",
          serverLocale: p.locale
        }),
        errorCode: "pay-individual-invoice-already-paid"
      };
    }

    const constrainedToMaxPaymentDetails = await fetchConstrainedToBalancePaymentDetails({
      accountId: p.accountId,
      nonDefaultPaymentMethodIdToUse: p.nonDefaultPaymentMethodIdToUse,
      orgInvoice: p.orgInvoice,
      ...p.todayPaymentDetails
    });

    if (!_.isEqual(constrainedToMaxPaymentDetails, p.todayPaymentDetails)) {
      logUnexpectedPaymentError({
        accountId: p.accountId,
        errorCode: "pay-invoice-charge-greater-than-max",
        info: {
          params: p,
          original: p.todayPaymentDetails,
          constrained: constrainedToMaxPaymentDetails
        },
        locationId: "123kjkdD93jdmdsf",
        olliePipe,
        paymentInfo: null,
        severity: "low"
      });
    }

    const result = await chargeWithNMIForInvoice({
      baseIdempotencyKey: p.orgInvoice.id + today + p.manualTriggerMS + (p.isImmediateRetry ? "-immediate-retry" : ""),
      orgInvoice: { ...p.orgInvoice },
      invoiceGroupId: p.orgInvoice.invoiceGroupId,
      orgId: p.orgInvoice.orgId,
      paymentDetails: constrainedToMaxPaymentDetails,
      accountIdToBeCharged: p.accountId,
      locale: p.locale,
      isManualChildInvoiceTrigger: !!p.manualTriggerMS,
      nonDefaultPaymentMethodIdToUse: p.nonDefaultPaymentMethodIdToUse
    });

    if (result.type === "error") {
      return result;
    } else if (result.type === "failed" && result.orgPayment) {
      try {
        await triggerForPaymentNotifications({
          type: "failed",
          failType: "failed-scheduled",
          orgPayment: result.orgPayment,
          orgInvoice: p.orgInvoice
        });
      } catch (e) {
        logUnexpectedPaymentError({
          errorCode: "pay-individual-invoice-failed-invoice-notifications-error",
          accountId: p.accountId,
          paymentInfo: result.orgPayment,
          olliePipe,
          info: e,
          locationId: "wq3e4kj234kjdkfjerkjKSD"
        });
      }
      return result;
    }

    const newAmountTotalPaid =
      constrainedToMaxPaymentDetails.baseAmountDueCents + p.orgInvoice.derivedTotalAmountPaidCentsBeforeAllFees;

    const parentInvoiceId = "parentOrgInvoiceId" in p.orgInvoice ? p.orgInvoice.parentOrgInvoiceId : p.orgInvoice.id;

    const [parentInvoice, siblingInvoices] = await Promise.all([
      ("parentOrgInvoiceId" in p.orgInvoice
        ? h.OrgInvoice.getDoc(p.orgInvoice.parentOrgInvoiceId)
        : p.orgInvoice) as OrgInvoiceParent,
      h.OrgInvoice.query({
        where: [{ parentOrgInvoiceId: ["==", parentInvoiceId] }]
      }).then(a => a.docs.filter(b => b.id !== p.orgInvoice.id) as OrgInvoiceChild[])
    ]);

    const derivedTotalAmountPaidCentsIncludingChildrenInvoices =
      (parentInvoice?.derivedTotalAmountPaidCentsBeforeAllFees ?? 0) +
      siblingInvoices.reduce((a, b) => a + b.derivedTotalAmountPaidCentsBeforeAllFees, 0) +
      newAmountTotalPaid;

    // Update the Invoice
    try {
      await h._BatchRunner.executeBatch([
        await h.OrgInvoice.update(
          {
            id: p.orgInvoice.id,
            doc: {
              derivedTotalAmountPaidCentsBeforeAllFees: newAmountTotalPaid,
              thisInvoicePaidInFullDateMS: newAmountTotalPaid >= p.orgInvoice.amountDueCents ? nowMS : undefined
            }
          },
          { returnBatchTask: true }
        ),
        await h.OrgInvoice.update(
          {
            id: parentInvoiceId,
            doc: {
              derivedTotalAmountPaidCentsIncludingChildrenInvoices,
              thisInvoicePaidInFullDateMS:
                parentInvoice.amountDueCents >= derivedTotalAmountPaidCentsIncludingChildrenInvoices ? nowMS : undefined
            }
          },
          { returnBatchTask: true }
        )
      ]);
    } catch (e) {
      logUnexpectedPaymentError({
        errorCode: "pay-individual-invoice-batch-tasks-error",
        locationId: "#KJDKJ#$KJ%(DSLK324kjJ",
        accountId: p.accountId,
        paymentInfo: result.orgPayment,
        olliePipe,
        info: e
      });

      return {
        type: "error",
        prettyErrorReason: translate({
          defaultMessage:
            "Something went wrong. Please do not submit again or you may be charged again. Please contact us at support@olliesports.com and we will resolve the situation.",
          serverLocale: p.locale
        }),
        errorCode: "pay-individual-invoice-completely-mysterious-error"
      };
    }

    try {
      if (result.orgPayment && result.orgPayment.type === OrgPaymentType.invoiceDefault) {
        triggerForPaymentNotifications(
          isParentOrgInvoice(p.orgInvoice)
            ? {
                type: "successfulParentOrgInvoicePayment",
                doesPaymentPlanExist: !!siblingInvoices.length,
                orgInvoiceParent: p.orgInvoice,
                orgPayment: result.orgPayment
              }
            : {
                type: "successfulChildOrgInvoicePayment",
                orgInvoiceChild: p.orgInvoice,
                orgPayment: result.orgPayment
              }
        );
      }
    } catch (e) {
      logUnexpectedPaymentError({
        errorCode: "pay-individual-invoice-notification-error",
        olliePipe,
        accountId: p.accountId,
        paymentInfo: result.orgPayment,
        info: e,
        locationId: "KDJFkjwleasdfas34234"
      });
    }

    return { type: "success" };
  } catch (e) {
    logUnexpectedPaymentError({
      errorCode: "pay-individual-invoice-completely-mysterious-error",
      olliePipe,
      accountId: p.accountId,
      paymentInfo: null,
      info: e,
      locationId: "KDJFkjwlekrjsdflkj34234"
    });
    return {
      type: "error",
      prettyErrorReason: translate({
        defaultMessage:
          "Something went wrong. Please do not submit again or you may be double charged. Please contact us at support@olliesports.com and we will resolve the situation.",
        serverLocale: p.locale
      }),
      errorCode: "pay-individual-invoice-completely-mysterious-error"
    };
  }

  // SERVER_ONLY_TOGGLE
}
